exports.components = {
  "component---src-dynamic-pages-document-page-tsx": () => import("./../../../src/dynamic-pages/document.page.tsx" /* webpackChunkName: "component---src-dynamic-pages-document-page-tsx" */),
  "component---src-dynamic-pages-education-rank-page-tsx": () => import("./../../../src/dynamic-pages/education-rank.page.tsx" /* webpackChunkName: "component---src-dynamic-pages-education-rank-page-tsx" */),
  "component---src-dynamic-pages-education-zone-page-tsx": () => import("./../../../src/dynamic-pages/education-zone.page.tsx" /* webpackChunkName: "component---src-dynamic-pages-education-zone-page-tsx" */),
  "component---src-dynamic-pages-home-page-tsx": () => import("./../../../src/dynamic-pages/home.page.tsx" /* webpackChunkName: "component---src-dynamic-pages-home-page-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-creator-preview-tsx": () => import("./../../../src/pages/creator-preview.tsx" /* webpackChunkName: "component---src-pages-creator-preview-tsx" */),
  "component---src-pages-document-preview-tsx": () => import("./../../../src/pages/document-preview.tsx" /* webpackChunkName: "component---src-pages-document-preview-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */)
}

